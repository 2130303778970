<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<button
  bitBadge
  [style.color]="textColor"
  [style.background-color]="color"
  appA11yTitle="{{ organizationName }}"
  (click)="emitOnOrganizationClicked()"
>
  {{ organizationName | ellipsis : 13 }}
</button>
